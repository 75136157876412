import { MuiButton } from 'settings/theme-components/button';
import { MuiDialog, MuiDialogTitle } from 'settings/theme-components/dialog';
import { MuiAvatar } from 'settings/theme-components/avatar';
import { MuiPaper } from 'settings/theme-components/paper';
import { MuiIconButton } from 'settings/theme-components/iconButton';
import { MuiChip } from 'settings/theme-components/chip';
import * as Inputs from 'settings/theme-components/inputs';

export const components: any = {
  ...Inputs,
  MuiButton,
  MuiAvatar,
  MuiDialog,
  MuiDialogTitle,
  MuiIconButton,
  MuiPaper,
  MuiChip,
};
