import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainProvider from 'contexts/MainProvider';
import './index.css';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MainProvider>
    <CookiesProvider
      defaultSetOptions={{
        path: '/',
        domain: process.env.REACT_APP_DOMAIN || '.qba.credit',
        httpOnly: true,
        secure: true,
        sameSite: 'none',
      }}
    >
      <App />
    </CookiesProvider>
  </MainProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
