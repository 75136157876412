import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ApiClientService, DefaultTokenService, useAuth } from '@dfl/react-security';
import Routes from 'routes';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

ApiClientService.setup({
  TokenService: DefaultTokenService,
});

function App() {
  const { setAuth } = useAuth();

  useEffect(() => {
    const handleMessage = async (event: any) => {
      if (event.data.type === 'authCompleted') {
        const { accessToken, refreshToken, space } = event.data.data;
        try {
          setAuth({
            accessToken,
            refreshToken,
            space,
          });
          toast.success('Autenticación completada exitosamente');
        } catch (error) {
          toast.error('Error al completar la autenticación');
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setAuth]);

  return <Routes />;
}
export default App;
